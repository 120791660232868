import { useStore } from '../util/store'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { findSlug } from '../util/helpers'
import Branding from './Branding'
import Content from './Content'
import NewsletterForm from './NewsletterForm'
import styled from 'styled-components'
import { GooSpinner } from './Spinner'

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: white;
  z-index: 100;
`

const Pages = () => {
  const store = useStore()
  const [ content, setContent ] = useState(null)
  const { l1, l2, l3, l4 } = useParams()
  const titleSuffix = ' - Old Cartshed Studio - Printmaking and Pottery'
  useEffect(() => {
    if (store) {
      let menu = store.menu
      let slug = l1 || 'home'
      if (l2) {
        slug = l2
        menu = findSlug(l1, menu).menu
      }

      if (l3) {
        slug = l3
        menu = findSlug(l2, menu).menu
      }

      if (l4) {
        slug = l4
        menu = findSlug(l3, menu).menu
      }

      setContent(findSlug(slug, menu))

    }
  }, [l1, l2, l3, l4, store])

  useEffect(() => {
    if(content) {
      document.title = content.title + titleSuffix
    }
  }, [content])

  return (
    <div>
      {store && content ?
      <>
        <Branding title={store.title} />
        <Content content={content} />
        <NewsletterForm />
      </>
        :
      <Loader>
        <img width={"50%"} alt="Old Cartshed Studio - Printmaking and Pottery" src={"assets/images/OCS_logo.png"} />
        <GooSpinner size={100} color={"#f26622"} />
      </Loader>
      }
    </div>
  )
}

export default Pages
import styled from 'styled-components';

export const StyledBranding = styled.div`
  font-family: Sattanic, serif;
  text-align: center;
  display: block;
  max-height: 60px;
  overflow: hidden;
  a {
    font-size: 2.5rem;
    color: ${({ theme }) => theme.primaryDark};
  }
`
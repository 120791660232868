import React, { useState, useRef, useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

const Location = props => {
  const { location } = props
  const [map, setMap] = useState(null)
  const mapContainer = useRef(null)
  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoibnVieiIsImEiOiJja2FteG40ZTQwb2xtMnJxd2JzbWl2MGxvIn0.fjkPrJ32LnSWSxV8thwGXw'
    const initializeMap = ({ setMap, mapContainer }) => {

      const m = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/light-v10",
        center: [location.longitude, location.latitude],
        zoom: 10,
        pitch: 45,
        bearing: -17.6,
        antialias: true
      });

      m.on("load", () => {
        setMap(m);
      });

      new mapboxgl.Marker()
        .setLngLat([location.longitude, location.latitude])
        .addTo(m)

    };

    if (!map && location.latitude) initializeMap({ setMap, mapContainer });
  }, [map, location.latitude, location.longitude]);

  return (
    <div ref={mapContainer} style={{marginBottom: '1em', width: '100%', height: '500px'}} />
  )
}

export default Location
import React from 'react'
import MailchimpSubscribe from "react-mailchimp-subscribe"
import styled from 'styled-components'

const Wrapper = styled.div`
  background: #000000;
  display: flex;
  align-items: center;
  flex-direction: center center;
  justify-content: space-around;
  background-image: url(assets/images/newsletter.jpg);
  background-attachment: fixed;
  width: 100%;
  padding: 2em;
  text-align: center;
  position: relative;
  margin-top: 5em;
  @supports (-webkit-overflow-scrolling: touch) {
    background-attachment: scroll;
  }
  > div {
    position: absolute;
    background: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    > div {
      color: white;
      margin: 1em auto;
      max-width: 600px;
    }
    h3 {
      color: white;
      margin-top: 2em;
      font-size: 2em;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        margin-top: 1.5em;
        font-size: 1.5em;
      }
    }
    button, input {
      padding: 5px 8px;
      font-size: 1.1rem;
    }
  }
`

const CustomForm = ({ status, message, onValidated }) => {
  let email
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value
    });

  return (
    <Wrapper className={'tile'}>
      <div>
        <h3>
          Old Cartshed Newsletter
        </h3>
        <div>
          Sign up and keep up to date with our latest courses, events and exhibitions.
        </div>
        {status === "sending" && (
          <div>
            sending...
          </div>
        )}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <input
          ref={node => (email = node)}
          type="email"
          placeholder="Your email"
        />
        <button onClick={submit}>
          Subscribe
        </button>
      </div>
    </Wrapper>
  );
};

export default props => {
  const url =
    'https://studio.us2.list-manage.com/subscribe/post?u=03fea3df3ffaa9548391daaa8&amp;id=284dce3a44'
  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={formData => subscribe(formData)}
        />
      )}
    />
    )
}

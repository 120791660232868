import React, { useRef, useState } from 'react'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import NotFoundPage from "./not-found.js"
import { ProvideStore } from '../util/store'
import { useOnClickOutside } from '../util/hooks'
import { ThemeProvider } from 'styled-components'
import { theme } from '../theme'
import { GlobalStyles } from '../global'
import Burger from '../components/Burger'
import Menu from '../components/Menu'
import Pages from '../components/Pages'
import FocusLock from 'react-focus-lock'
import Social from '../components/Social/Social'

function App(props) {
  const [open, setOpen] = useState(false)
  const node = useRef()
  const menuId = "main-menu"

  useOnClickOutside(node, () => setOpen(false))

  return (
    <ProvideStore>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <BrowserRouter>
            <div ref={node}>
              <FocusLock disabled={!open}>
                <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
                <Menu open={open} setOpen={setOpen} id={menuId} />
              </FocusLock>
              <Social />
            </div>
            <Routes>
              <Route path="/" element={<Pages />} />
              <Route path=":l1" element={<Pages />} />
              <Route path=":l1/:l2" element={<Pages />} />
              <Route path=":l1/:l2/:l3" element={<Pages />} />
              <Route path=":l1/:l2/:l3/:l4" element={<Pages />} />
              <Route component={NotFoundPage} />
            </Routes>
            <RouteContainer setOpen={setOpen} />
          </BrowserRouter>
        </>
      </ThemeProvider>
    </ProvideStore>
  )
}

function RouteContainer({ setOpen }) {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0)
    setOpen(false)
  }, [location, setOpen]);

  return null
}

export default App

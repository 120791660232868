import styled from 'styled-components';

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.primaryLight};
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  min-width: 300px;
  text-align: center;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
  transition: transform 0.3s ease-in-out;
  -webkit-box-shadow: 1px 0px 4px 0px rgba(0,0,0,0.16);
  -moz-box-shadow: 1px 0px 3px 0px rgba(0,0,0,0.16);
  box-shadow: 1px 0px 4px 0px rgba(0,0,0,0.16);
  @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 100%;
    }
  a {
    font-size: 1.3rem;
    padding: 1rem 0;
    font-weight: bold;
    color: ${({ theme }) => theme.primaryDark};
    text-decoration: none;
    transition: color 0.3s linear;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.5rem;
      text-align: center;
    }
    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }
`;
import React from 'react'

const IFrame = props => {
  const { url, platform } = props
  const height = platform === 'soundcloud' ? 180 : (platform === 'soundcloudSet' ? 360 : 460)
  return (
    <div className={"container"}>
      <iframe sandbox="allow-presentation allow-same-origin allow-scripts allow-popups allow-forms" title={'preview'} src={url} width="100%" height={height} frameBorder="0" allowFullScreen={true} />
    </div>
  )
}

export default IFrame
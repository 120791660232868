import React from 'react'
import { StyledSocial } from './Social.styled'
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const Social = () => {
  return (
    <StyledSocial>
      <a href="https://www.facebook.com/OldCartshed/" target={"_blank"} rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebookSquare} />
      </a>
      <a href="https://www.instagram.com/oldcartshedstudio/" target={"_blank"} rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
    </StyledSocial>
  )
}

export default Social
import styled from 'styled-components';

export const StyledSocial = styled.div`
  position: absolute;
  top: 22px;
  right: 5px;
  display: block;
  max-height: 60px;
  @media (max-width: 600px) {
    top: 68px;
  }
  a {
    display: inline-block;
    margin-right: 15px;
    color: #f26622;
  }
`
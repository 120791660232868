export const findSlug = (slug, menu) => {
  return menu.find(m => m.slug === slug)
}

export const resizeImg = (url, size) => url.indexOf('cloudinary') !== -1
  ? url.replace('upload/', `upload/w_${size}/`) : url

export const makeGallery = data => data.reduce((list, next) => {
  const image = {}
  image.original = resizeImg(next.image, 1200)
  image.thumbnail = resizeImg(next.image, 100)
  list.push(image)
  return list
}, [])
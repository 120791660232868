import React, { useContext, createContext, useState, useEffect } from 'react'

const storeContext = createContext('')
const warehouse = 'xUE5JN9MIu5BaRUFuVgd'
//const warehouse = 'VuOWUXgzHCtX1Bw63Zac'
const id = 'VuAc1ZqBkKwHp1jiikrp'
//const id = 'pQ9IN3hqM2AFyObYMbiH'
const apiUrl = `https://europe-west1-publishr-725ea.cloudfunctions.net/api2/menu/${warehouse}/${id}`

export function ProvideStore({ children }) {
  const { store } = useProvideStore();
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export const useStore = () => {
  return useContext(storeContext);
}

function useProvideStore() {
  const [active, setActive ] = useState(true)
  const [store, setStore] = useState(null);
  useEffect(() => {
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        if (active) {
          setStore(data)
        }
      })
      .catch((error) => console.log(error.message))

    return () => {
      setActive(false)
    }
  }, [active])
  return {
    store: store
  }
}

import React from 'react'
import IFrame from './IFrame'
import Location from './Location'
import { makeGallery, resizeImg } from '../util/helpers'
import ImageGallery from 'react-image-gallery'
import { Link } from 'react-router-dom'
import './Content.css'

const Content = (props) => {
  const { content } = props
  if (content) {
    return (
      <>
        {content.type !== 'gallery' &&
          <div
            className={'hero'}
            style={{backgroundImage: `url(${resizeImg(content.image, 1200)})`}}
          />
        }
        <div className='content'>
          <h1 className={'title'}>
            {content.title}
          </h1>
          <p style={{textAlign: 'center'}}>
            {content.description}
          </p>
          {content.type === 'gallery' &&
            <ImageGallery items={makeGallery(content.menu)}/>
          }
          {content.type === 'video' &&
            <IFrame url={content.content.url} platform={content.content.platform}/>
          }
          {content.type === 'image' &&
            <img alt={content.title} src={resizeImg(content.image, 1200)}/>
          }
          {content.type === 'location' &&
            <Location location={content} />
          }
          {content.type === 'article' &&
            <div
              style={{maxWidth: '720px', margin: '2rem auto'}}
              dangerouslySetInnerHTML={{__html: content.content.html}}
            />
          }
          {content.menu &&
            content.menu.length > 0 &&
            content.type !== 'gallery' &&
              <ul style={{listStyle: 'none', padding: 0, margin: 0}}>
                {content.menu.map(c => (
                  <li className={'tile-wrapper'} key={`inline-link-${c.id}`}>
                    <Link to={c.slug}>
                      <div className='tile' style={{
                        backgroundImage: `url(${resizeImg(c.image, 300)}`,
                      }}>
                        <p>{c.title}</p>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
          }
        </div>
      </>
    )
  } else {
    return <div>content not found</div>
  }
}

export default Content

import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: Sattanic;
    src: url('/assets/fonts/SattanicItalic.ttf') format('truetype');
  }
  html, body {
    margin: 0;
    padding: 0;
  }
  *, *::after, *::before {
    box-sizing: border-box;
  }
  body {
    background: ${({ theme }) => theme.primaryLight};
    color: ${({ theme }) => theme.primaryDark};
    height: 100vh;
    font-size: 19px;
    text-rendering: optimizeLegibility;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
  small {
    display: block;
  }
  h1 {
    font-family: Sattanic, Arial;
    font-weight: normal;
    text-align: center;
  }
  a {
    color: ${({ theme }) => theme.primaryHover};
    text-decoration: none;
  }
  .branding {
    font-family: Sattanic, serif;
    font-size: 2.5rem;
    text-align: center;
    display: block;
    color: ${({ theme }) => theme.primaryDark};
  }
  .hero {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 400px;
    @supports (-webkit-overflow-scrolling: touch) {
      background-attachment: scroll;
    }
    text-align: center;
  }
  .tile {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
  }
  .tile-wrapper {
    display: inline-block;
    width: 30%;
    margin-right: 10px;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 100%;
      margin-right: 0;
    }
  }
  .tile p {
    color:  ${({ theme }) => theme.primaryLight};
    background:  ${({ theme }) => theme.primaryDark};
    text-align: center;
    padding: 6px 3px;
  }
  .content {
    max-width: 900px;
    box-sizing: border-box;
    padding: 0 1rem;
    margin: 2rem auto;
  }
`
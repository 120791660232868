import React from 'react';
import { StyledMenu } from './Menu.styled';
import { useStore } from '../../util/store'
import { Link } from 'react-router-dom'
import Social from '../Social/Social'

const Menu = ({ open, ...props }) => {
  const isHidden = !!open
  const tabIndex = isHidden ? 0 : -1;
  const store = useStore()
  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      {store && store.menu && store.menu.length > 0 &&
        <>
        <Link to="/">Home</Link>
        {store.menu.filter(s => s.slug !== 'home').map(m => (
          <Link tabIndex={tabIndex} key={`link-${m.id}`} to={`/${m.slug}`}>{m.title}</Link>
        ))}
        </>
      }
      <Social />
    </StyledMenu>
  )
}

export default Menu

import React from 'react'
import { StyledBranding } from './Branding.styled'
import { Link } from 'react-router-dom'

const Branding = ({ title }) => {
  return (
    <StyledBranding>
      <Link to={"/"}>
        {title}
      </Link>
    </StyledBranding>
  )
}

export default Branding